.custom-navbar {
    transition: 0.3s;
    background: #FFF;
    padding: 0.5rem 60px !important;

    .container-fluid {
        padding: 0 70px;
    }

    &__logo {
        margin-right: 20px;
        height: 36px;
    }

    .nav-link {
        margin-right: 50px;
    }

    .settings {
        margin: 0 15px;
    }

    &__searchbox {
        .form-control {
            background-color: #C4C4C4;
            border-radius: 0;
            color: #12121299;
        }
    }


    .profile-dropdown .dropdown-toggle::after {
        display: none;
    }

    .user-img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        align-self: center;
        border-radius: 50%;
    }

    .language-picker { 
        img {
            height: 24px;
        }

        .btn-primary {
            position: relative;
            background-color: #FFF;
            box-shadow: none;
            border-color: #FFF;
            border-radius: 0;
        }
        .dropdown-menu {
            border-radius: 0;
        }

        .dropdown-toggle::after {
            display: none;
        }
    }

}

.custom-navbar-onscroll {
    transition: 0.3s;
    box-shadow: 0px 5px 15px rgba(177, 165, 165, 0.28);
}

.test-fork {
    color: pink;
}

@media only screen and (max-width: 600px) {
    nav.custom-navbar {
        padding-left: 20px;
        padding-right: 20px;
    }
}