.payment-method {
    &__accordion {
        .card {
            color: #FFFFFF;
            border-radius: 0;
            margin-bottom: 16px;
        }

        .card:nth-child(1) {
            .collapse, .collapsing {
                border-color: #E30377;
            }
            svg:last-child path {
                stroke: #E30377;
            }
        }
        .card:nth-child(2) {
            .collapse, .collapsing {
                border-color: #328CF6;
            }
            svg:last-child path {
                stroke: #328CF6;
            }
        }
        .card:nth-child(3) {
            .collapse, .collapsing {
                border-color: #FFBA26;
            }
            svg:last-child path {
                stroke: #FFBA26;
            }
        }
        .card:nth-child(4) {
            .collapse, .collapsing {
                border-color: #19A0AF;
            }
            svg:last-child path {
                stroke: #19A0AF;
            }
        }

        .card:nth-child(5) {
            .collapse, .collapsing {
                border-color: #E30377;
            }
            svg:last-child path {
                stroke: #E30377;
            }
        }
        .card:nth-child(6) {
            .collapse, .collapsing {
                border-color: #328CF6;
            }
            svg:last-child path {
                stroke: #328CF6;
            }
        }
        .card:nth-child(7) {
            .collapse, .collapsing {
                border-color: #FFBA26;
            }
            svg:last-child path {
                stroke: #FFBA26;
            }
        }
        .card:nth-child(8) {
            .collapse, .collapsing {
                border-color: #19A0AF;
            }
            svg:last-child path {
                stroke: #19A0AF;
            }
        }
        .collapse {
            border: 1px solid;
            border-top-width: 8px;
        }

        .collapsing {
            border: 1px solid;
            border-top-width: 8px;
        }

        .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #1A1A1A;
            padding: 15px 24px;
            cursor: pointer;
            border-radius: 0;

            svg:last-child path:last-child  {
                stroke: #FFFFFF;
            }
        }

        .card-body {
            background: #FFFFFF;
            color: #1A1A1A;
            padding: 0;
        }
        
        .payment-method-item {
            position: relative;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 24px 34px;
            cursor: pointer;
        }

        .payment-method-item:hover {
            background: #F9F9F9;
            transition: 0.3s;
        }

        .payment-method-item::after {
            display: block;
            content: '';
            width: calc(100% - 68px);
            height: 1px;
            background: #EDEDED;
            position: absolute;
            bottom: 0;
        }

        .payment-method-item div svg:last-child {
            margin-left: 16px;
        }
    }

  .tetris-decoration {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 80px;
    left: 0;
    z-index: -1;
  
    img:nth-child(1) {
      position: absolute;
      top: 30px;
      left: 0;
    }
  
    img:nth-child(2) {
      position: absolute;
      bottom: 80px;
      right: 0;
    }
  }
}