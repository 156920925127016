@font-face {
   font-family: Aileron;
   src: url(../assets/fonts/Aileron-Regular.woff2);
   font-weight: 400;
}

@font-face {
   font-family: Aileron;
   src: url(../assets/fonts/Aileron-Bold.woff2);
   font-weight: 600;
}

@font-face {
   font-family: LeagueSpartan;
   src: url(../assets/fonts/LeagueSpartan-Bold.woff2);
   font-weight: bold;
}

body {
   font-family: Aileron;
}

h1,
h2,
h3 {
   font-family: LeagueSpartan;
}

h4 {
   font-size: 24px;
   font-weight: bold;
}
div.row,
div.col {
   margin: 0;
   padding: 0;
}

a {
   text-decoration: none;
}

.content-wrapper {
   padding-bottom: 60px;
   max-width: 1600px;
   margin: 0 auto;
}

.wrap-content {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
}

div {
   scrollbar-width: thin;
   scrollbar-color: $color-black;
 }
 
div::-webkit-scrollbar {
   width: 15px;
 }
 
div::-webkit-scrollbar-track {
   background: #C4C4C4;
 }
 
div::-webkit-scrollbar-thumb {
   background-color: $color-black;
   border-radius: 0px;
 }
 
div::-webkit-scrollbar-corner {
   background: #C4C4C4;
}

.cursor-pointer{
   cursor: pointer;
 }