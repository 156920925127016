.nav-card.card {
    display: flex;
    max-width: 300px;
    border-radius: 0;
    border: none;
    padding: 0;
    margin: 12px;
    overflow: hidden;
    cursor: pointer;

    .card-img {
      align-self: center;
    }
}

.nav-title {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-top: 10px;
    padding-bottom: 4px;
    width: 100%;
    z-index: 1;

    h5 {
      font-weight: 700;
      text-align: center;
      color: #FFFFFF;
    }

    .inactive {
      color: #AAAAAA
    }
  }

