.dashboard-sidebar-wrapper {
    position: relative;

    .mini-sidebar-toggle {
        position: absolute;
        visibility: hidden;
    }
}

.dashboard-sidebar-wrapper ~ .content-wrapper {
    overflow: hidden;
}

.dashboard-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    width: 470px;
    transition: 0.3s;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    .sidebar-logo {
        height: 37px;
        margin: 27px 0 45px 0;
    }

    .dashboard-sidebar-toggle {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        margin-top: 35px;

        span {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 19px;
        }
    }
    .nav {
        display: flex;
        flex-direction: column;
        width: 100%;

        .nav-item {
            margin: 14px 0;
            padding: 0 40px;

            a {
                padding: 0;
            }

            img {
                width: 28px;
                height: 28px;
            }

            span {
                margin-left: 10px;
                color: #121212;
            }
        }

        .nav-item.active {
            position: relative;
        }

        .nav-item.active::after {
            position: absolute;
            right: 0;
            top: 0;
            display: block;
            content: "";
            width: 4px;
            height: 100%;
            background: #0372f4;
            border-radius: 1px;
        }
        .sidebar-divider {
            width: calc(100% - 80px);
            height: 0.6px;
            background: rgba(18, 18, 18, 0.6);
            margin: 20px 0 20px 40px;
        }
    }

    .sidebar-subtitle {
        padding: 0 40px;
        margin-bottom: 20px;
    }

    .sidebar-user-profile {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin: 30px 0;
        padding: 0 40px;
    }

    .sidebar-user-profile div:nth-child(1) {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        background: rgb(197, 197, 197);
    }

    .sidebar-user-profile div:nth-child(2) {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
    }
}

.dashboard-sidebar-collapse {
    max-width: 110px;
    transition: 0.3s;

    .dashboard-sidebar-toggle {
        img {
            transform: rotate(180deg);
        }
        span {
            display: none;
        }
    }

    .sidebar-subtitle {
        display: none;
    }

    .nav-link {
        span {
            display: none;
        }
    }
    .sidebar-user-profile {
        padding: 0;
        justify-content: center;
    }
    .sidebar-user-profile div:nth-child(2) {
        display: none;
    }
}

div.dashboard-sidebar::-webkit-scrollbar {
    width: 10px;
}

div.dashboard-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(180, 180, 180, 0.438);
    border-radius: 0px;
}

div::-webkit-scrollbar-track {
    background: #f5f5f5;
}

@media only screen and (max-width: 1439px) {
    .dashboard-sidebar {
        width: 290px;
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-sidebar {
        position: absolute;
        z-index: 1;
    }

    .dashboard-sidebar-wrapper {
        position: absolute;
        top: 0;
        left: 0;

        .mini-sidebar-toggle {
            visibility: visible;
            position: absolute;
            top: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px;

            span {
                margin-left: 8px;
            }
        }
    }
    .dashboard-sidebar {
        max-width: 80vw;
    }
    .dashboard-sidebar-collapse {
        max-width: 0;
    }
}
