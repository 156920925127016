.correct-answer-modal :global .modal-content {
    border-radius: 0;
}

.correct-answer-modal h5 {
    font-weight: bold;
}

.quiz-answer>div {
    display: flex;
    align-items: center;
}

.quiz-answer>div::before {
    position: relative;
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #EDEDED;
    margin-right: 12px;
    margin-left: 8px;

}

.quiz-answer>.quiz-correct-answer-item::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    background-color: #0372F4;
    margin-left: 0px;
}

.quiz-answer-item {
    margin-bottom: 12px;
    flex: 1;
}

.quiz-answer-item > div, 
.quiz-correct-answer-item > div {
    flex: 1;
}

.quiz-correct-answer-item {
    position: relative;
    background-color: #EDEDED;
    padding: 8px;
    border-radius: 20px;
    margin-bottom: 12px;
    flex: 1;
}

.quiz-correct-answer-item>svg {
    position: absolute;
    top: 8px;
    left: 10px;
}