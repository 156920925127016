.payment-status {
    &__content-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 80vh;

        .black-button {
            align-self: center;
            color: #FFFFFF;
        }

        .pending-image {
            width: 50%;
            align-self: center;
        }
    }

    .tetris-decoration {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 80px;
      left: 0;
      z-index: -1;
    
      img:nth-child(1) {
        position: absolute;
        top: 30px;
        left: 0;
      }
    
      img:nth-child(2) {
        position: absolute;
        bottom: 80px;
        right: 0;
      }
    }
}