.subscription {
    .pricing-tabs {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-self: center;
        border-bottom: none;
        border: 1px solid #121212;
        border-radius: 5px;
        width: fit-content;

        .nav-item {
            border: none;
            border-radius: 4px;
            color: #121212;
        }

        .nav-item.active {
            background: #0372F4;
            color: #FFFFFF;
        }
    }
    .tab-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 92px;
        width: 100%;

        .tab-pane {
            display: none;
        }
        .tab-pane.active {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }
    .subscription-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 530px;
        padding: 40px 32px;
        margin: 0 10px;
    }
    .subscription-card-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 32px;

        .subscription-card-top-left {
            width: 210px;
            
            
            div:nth-child(1) {
                font-family: LeagueSpartan;
                font-size: 26px;
                font-style: normal;
                font-weight: 700;
                line-height: 33px;
                letter-spacing: 0em;
                text-align: left;
                color: #121212;
            }
            div:nth-child(2) {
                background: #FFBA26;
                border-radius: 4px;
                padding: 9px 14px;
                margin-top: 9px;
            }
        }

        .subscription-card-top-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: #FFBA26;
            border-radius: 6px;
            padding: 12px 29px;

            div:nth-child(1) {
                font-family: LeagueSpartan;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                letter-spacing: 0em;
                text-align: left;
                color: #121212;
            }

            div:nth-child(2) {
                font-family: LeagueSpartan;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #121212;
            }
        }
    }
    .subscription-card-content {
        margin-bottom: 36px;
        
        div {
            margin-bottom: 12px;
            img {
                margin-right: 8px;
            }
        }
    }

    .subscribe-button {
        background: #E30377;
        border-radius: 4px;
        text-align: center;
        color: #FFFFFF;
        font-family: LeagueSpartan;
        padding-top: 16px;
        padding-bottom: 9px;

        &:hover {
            background: #0372F4;
        }
    }


    @media only screen and (max-width: 600px) {
        .pricing-tabs {
            flex-direction: column;

            a {
                text-align: center;
            }
        }
        .subscription-card {
            width: 100%;
            margin: 0 0 20px 0;
        }
        .subscription-card-top {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .subscription-card-top-left {
                div:nth-child(1) {
                    text-align: center;
                }
            }
            .subscription-card-top-right {
                margin-top: 10px;
            }
        }
    }
}