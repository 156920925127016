$color-black : #121212;
$color-black-hover : #303030;

$color-white : #FFF;
$color-pink : #E30377;
$color-pink-hover : #eb2f90;
$color-yellow : #FFBA26; 
$color-green : #19A0AF;

$color-blue :#0372F4;
$color-blue-hover :#0268dd;
$color-blue-overlay : #0FB9F3;

$color-overlay : #F5F5F5;
$color-overlay-dark : #C4C4C4;