.primary-button {
    border-radius: 0;
    padding: 16px 55px;
    font-size: 22px;
    line-height: 26px;
}

.black-button {
    background: $color-black;
    padding: 17px 45px;
    font-size: 22px;
    border: none;
    border-radius: 0px;
	box-shadow: .4em .3em $color-pink;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
        background: $color-black-hover;
    }
    &:disabled {
        background:darkgray
    }

    img {
        margin-left: 10px;
    }
}

.small-black-button {
    background: $color-black;
    padding: 10px 55px;
    font-size: 22px;
    border: none;
    border-radius: 0px;
	box-shadow: .4em .3em $color-pink;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
        background: $color-black-hover;
    }
    

    img {
        margin-left: 10px;
    }
}

.white-button {
    background: $color-white;
    padding: 17px 45px;
    font-size: 22px;
    border: 1px solid $color-black;
    border-radius: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $color-black;
    

    &:hover {
        background: $color-white;
        color: $color-black;
    }

    

    img {
        margin-right: 10px;
    }
}



.blue-button {
    background: $color-blue;
    color: $color-white;
    padding: 17px 45px;
    font-size: 22px;
    border: none;
    border-radius: 0px;
	box-shadow: .4em .3em $color-pink;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;

    &:hover {
        background: $color-blue-hover;
        transition: 0.3s;
    }

    img {
        margin-left: 10px;
    }
}


.pink-button {
    background: $color-pink;
    color: $color-white;
    padding: 17px 45px;
    font-size: 20px;
    border: none;
    border-radius: 6px;
    text-align: center;

    &:hover {
        background: $color-pink-hover;
        transition: 0.3s;
        color: $color-white;
    }

    img {
        margin-left: 10px;
    }
}
.button-group {
    display: flex;
    flex-direction: row;
}



@media only screen and (max-width: 600px) {
    .primary-button {
        padding: 12px 32px;
    }
}